<template>
    <cluji-card subtitle="Statut de SlimyBot">
        <template #title>
            Bot discord
            <b-button pill variant="secondary" size="sm" class="ml-2" @click="loadStatus" :disabled="loading" v-b-tooltip.hover="'Actualiser'">
                <font-awesome-icon icon="sync" :spin="loading"/>
            </b-button>
        </template>
        <div class="text-center">
            <b-img :class="imageClass" :src="image"/>
            <h1 class="m-2">{{ statusText }}</h1>
        </div>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import('@/components/ClujiCard');

    import {requireImg} from '@/util/resources';
    import { apiPath }  from "@/util/http";

    export default {
        name: 'WebcretariumDiscordBot',
        data: () => ({
            alive: null,
            loading: false,
            nextReload: null,
        }),
        components: {ClujiCard},
        computed: {
            image() {
                return requireImg('slimy_bot_icon.png');
            },
            imageClass() {
                return this.alive ? ['pulse', 'bot-logo'] : ['grayscale', 'bot-logo'];
            },
            statusText() {
                return this.alive ? 'Fonctionnement normal' : (this.alive === false ? 'SlimyBot est arrêté' : 'État inconnu');
            }
        },
        methods: {
            loadStatus() {
                if (this.loading) return;

                if (this.nextReload) {
                    clearTimeout(this.nextReload);
                    this.nextReload = null;
                }

                this.loading = true;
                this.axios.get(apiPath('webcretaire_discord_bot_alive'))
                    .then(response => this.alive = response.data.alive)
                    .catch(() => this.$toaster.error('Impossible de retrouver le statut du bot Discord'))
                    .finally(() => {
                        this.loading = false;
                        this.nextReload = setTimeout(() => this.loadStatus(), 30000);
                    });
            }
        },
        mounted() {
            this.loadStatus();
        }
    };
</script>

<style lang="scss" scoped>
    $pulse-radius: 2rem;
    $drop-shadow: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.25));

    h1 {
        font-weight: 200;
    }

    .bot-logo {
        margin: $pulse-radius;
        border-radius: 50%;
        filter: $drop-shadow grayscale(0%);
        transition: 1s filter;
    }

    .grayscale {
        filter: $drop-shadow grayscale(100%);
    }

    .pulse {
        box-shadow: 0 0 0 var(--pulse-color-opaque);
        animation: pulse 2s infinite;
        --pulse-color-opaque: rgba(0, 255, 0, 0.5);
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 var(--pulse-color-opaque);
        }
        70% {
            -webkit-box-shadow: 0 0 0 $pulse-radius transparent;
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 transparent;
        }
    }

    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 var(--pulse-color-opaque);
            box-shadow: 0 0 0 0 var(--pulse-color-opaque);
        }
        70% {
            -moz-box-shadow: 0 0 0 $pulse-radius transparent;
            box-shadow: 0 0 0 $pulse-radius transparent;
        }
        100% {
            -moz-box-shadow: 0 0 0 0 transparent;
            box-shadow: 0 0 0 0 transparent;
        }
    }
</style>